// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Erfahrungsbericht von Familie Gasser aus Suhr AG"
        meta={[
          { name: 'description', content: 'Wärmepumpen Erfahrungsbericht der Familie Gasser ➤ Heizungsersatz ✔️ Umweltfreundliche Luft/Wasser-Wärmepumpe ✔️' },
          { name: 'keywords', content: 'Erfahrungsbericht Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='5YRW1ExtQND3QS36ncHYw6-2v8bBdpkEu0pNBFKhTlEPw'
          id='familie-gasser-suhr-ag'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "2v8bBdpkEu0pNBFKhTlEPw",
    "updatedAt": "2023-05-17T11:03:15.027Z",
    "title": "FAMILIE GASSER, SUHR AG",
    "size": "Groß",
    "teaser": "Das Haus von Beat Gasser ist jetzt mit effizienten und erneuerbaren Energiequellen ausgestattet! Im Interview erklärt Beat Gasser, warum er seine 30 Jahre alte Ölheizung ersetzen liess, wie er auf die Heizungsmacher AG gestossen ist und welcher Nutzen die Investition in eine Photovoltaikanlage hat. Mehr dazu erfahren Sie in diesem Artikel.",
    "image": {
        "description": "Titelbild Erfahrungsbericht Familie Gasser aus Suhr AG",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/2a1fknEyTJpkSjHRgPFuBa/cd5b32a5220af4312dd52b129d8dab10/2302---Titelbild-Referenzstory---Hero-Gross.png"
    },
    "anchorName": "familie-gasser-suhr-ag"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='5YRW1ExtQND3QS36ncHYw6-3l7wjzqvKfopeRHsoer0Gr'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "3l7wjzqvKfopeRHsoer0Gr",
    "updatedAt": "2023-05-17T11:10:28.973Z",
    "title": "FAMILIE GASSER, SUHR AG",
    "steps": [
        {
            "type": "howToStep",
            "id": "3WlUXhYvG7SPn5rQUmowEw",
            "updatedAt": "2023-05-17T11:13:28.248Z",
            "title": "Familie Gasser wusste schon vor den GEAK Ergebnissen, dass die neue Heizung eine Wärmepumpe sein wird",
            "description": "<p>Die Familie Gasser hat kurz vor ihrer Heizungssanierung den schweizweit einheitlichen Gebäudeenergieausweis der Kantone (GEAK) Plus erstellen lassen, um herauszufinden, welche energetischen Sanierungen bei ihrem Haus noch notwendig sind - die Familie wusste jedoch schon vor den GEAK Ergebnissen, dass die neue Heizung eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpe</a> sein wird.</p>\n",
            "image": {
                "description": "Ein vorher nachher Foto von Familie Gassers Keller, ein Heizungsmacher Kunde",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/KNp1LJxM1qiiUTtKiEuZe/1479ebfc229934d1f8fecab11645a58a/2302---Bild-Anleitungsschritt-2.png"
            }
        },
        {
            "type": "howToStep",
            "id": "3TiYPbIExTUxh1w7fEM3m5",
            "updatedAt": "2023-05-17T11:13:50.315Z",
            "title": "\"Wenn schon, denn schon!\": Beat Gasser im Interview über seine Wärmepumpe und Photovoltaik-Anlage.",
            "description": "<p>Die Empfehlung, eine Photovoltaikanlage einzubauen, kam vom Geak Plus Bericht. Dies ist sinnvoll, weil bekanntlich aus Sonnenenergie Strom erzeugt werden kann, und zwar emissionsfrei und klimafreundlich. Wenn man eine Wärmepumpe mit der <a href=\"https://www.heizungsmacher.ch/waermepumpe-photovoltaik/\">Photovoltaikanlage</a> verbindet, entsteht ein Synergieeffekt, von dem Familie Gasser nun profitieren darf.</p>\n",
            "image": {
                "description": "Die Photovoltaikanlage von Familie Gasser aus Suhr AG, ein Kunde der Heizungsmacher AG",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2n2paENO58VaCQocCmpHUg/0f9dda8100a9464ae21904a917ff8f6b/2302---Bild-Anleitungsschritt-1.png"
            }
        },
        {
            "type": "howToStep",
            "id": "5cNIquPlA5hYBrmyGWNlXV",
            "updatedAt": "2023-05-17T11:14:22.813Z",
            "title": "Die innenaufgestellte Wärmepumpe – die perfekte Lösung für die Familie Gasser",
            "description": "<p>Nach reiflicher Überlegung entschied sich die Familie Gasser für eine diskrete Wärmepumpe im Innenbereich. Nach dem Rückbau des Öltanks würde der Raum ohnehin leer stehen. Sie mussten jedoch feststellen, dass keiner der angefragten Anbieter eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/luft-wasser-innenaufstellung/\">Innenaufstellung</a> offertiert hatte - bis sie schliesslich auf die Heizungsmacher AG stiessen, die diese innovative Lösung vorschlugen.</p>\n",
            "image": {
                "description": "Ein Foto von der innenaufgestellten Wärmepumpe der Familie Gasser aus Suhr AG",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7cFndmubDW9WuuFYDNvaq2/1a7d08c305bdb6b2b2cfb04ad8cafceb/2302---Bild-Anleitungsschritt-3.png"
            }
        },
        {
            "type": "howToStep",
            "id": "7Fuwe2YP3s9b2KyYOboVPC",
            "updatedAt": "2023-05-17T11:09:02.977Z",
            "title": "Weshalb Heizungsmacher?",
            "description": "<p>Nach einem ausführlichen Beratungsgespräch waren sich die Gassers mehr als sicher, den perfekten Partner für ihren Heizungsaustausch und den Einbau einer Photovoltaikanlage gefunden zu haben. Mit dem Versprechen, sowohl die technischen Details als auch den administrativen Papierkram von Anfang bis Ende im Griff zu haben, war klar, warum die Heizungsmacher für einen reibungslosen Ablauf unabdingbar war: kompetente Beratung und fachmännisch organisierte Ausführung. Einzig der Übergang zur Projektplanung war etwas holprig, da einige Details übersehen wurden, erzählt Beat Gasser. Nachdem sich der Projektleiter mit ihm in Verbindung gesetzt und die wichtigsten Punkte erneut besprochen hatte, stand einer erfolgreichen Projektumsetzung nichts mehr im Wege.</p>\n",
            "image": {
                "description": "Vorher nachher Foto vom Keller der Familie Gasser aus Suhr AG nach Heizungsersatz",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/673QADSXVrh6JDHaiaLMqL/693b4b23b98933835743f7005a3ca647/2302---Bild-Anleitungsschritt-6.png"
            }
        },
        {
            "type": "howToStep",
            "id": "6xEcgOgrZTvHjvKetyPIAA",
            "updatedAt": "2023-05-17T11:10:05.928Z",
            "title": "Wir könnten nicht zufriedener sein",
            "description": "<p>Ein leichtes Brummen sei im Innern des Hauses tatsächlich zu hören. Denn die innenaufgestellte Wärmepumpe erzeugt einen Körperschall, welcher durch den Kompressionsvorgang des Kältemittels stattfindet. Dies kann als ein leichtes Brummen wahrgenommen werden - ähnlich wie bei einem Kühlschrank.\nAnfänglich hatten sie jedoch Bedenken, von äusserlichen Lärmemissionen gestört zu werden, was sich aber nicht bewahrheitet hatte. So geniessen sie weiterhin ungestört auf Ihrem Sitzplatz einen Kaffee, obwohl sich in der Nähe die Luftkanäle befinden. Es sei kaum ein Flüstern zu hören. Noch besser: Die App, die die Stromerzeugung der Photovoltaikanlage und den Stromverbrauch der Wärmepumpe übersichtlich und grafisch anzeigt - die Gassers sind begeistert!</p>\n<p>Wir von Heizungsmacher möchten Herrn Beat Gasser unseren Dank für seinen Einblick, die Bereitschaft, seine Geschichte zu teilen und für die aufrichtige Bewertung aussprechen - vielen Dank, wir wissen Ihre Zeit wirklich zu schätzen!</p>\n",
            "image": {
                "description": "Ein Foto vom Einfamilienhaus der Familie Gasser aus Suhr AG",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/iJnUwcll8lKRPKRzRwg7l/48c0252cd31bcb50cbbe52841b942614/2302---Bild-Anleitungsschritt-5.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='5YRW1ExtQND3QS36ncHYw6-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
